import React from 'react';
import ContextProvider from '../../components/ContextProvider';
import VerifyEmailGate from '../../components/Account/VerifyEmailGate';

const VerifyEmailPage = ({ localeCode = 'en-US' }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <VerifyEmailGate />
  </ContextProvider>
);

export default VerifyEmailPage;
