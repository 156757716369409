import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import { User, onAuthStateChanged, reload } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex } from 'rebass/styled-components';
import { setAuth } from '../../utils/Auth';
import { firebaseAuth, firestore } from '../../utils/Firebase';
import { LocaleContext, UserContext } from '../ContextProvider';
import { Layout } from '../Layout';
import Section from '../Section';
import TextLibrary from '../TextLibrary';
import VerifyEmailButton from './VerifyEmailButton';

const VerifyEmailGate: React.FC = () => {
  const { t } = useTranslation('verifyEmail');

  // User Context
  const pageLocation = useLocation();
  const userContext = useContext(UserContext);
  const localeData = useContext(LocaleContext);
  const { user, updateUserContext } = userContext;
  const { rootPath } = localeData;

  let redirectUrl = rootPath;
  if (pageLocation.state && pageLocation.state.redirectUrl) {
    redirectUrl = pageLocation.state.redirectUrl;
  }

  // firebase instance to check email verification
  const [fbAuthUser, setFbAuthUser] = useState<User>();

  // Countdown timer to prevent people spamming the send email verification button on fail
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingIsActive, setPollingIsActive] = useState(false);

  // Start polling if email isn't verified
  useEffect(() => {
    if (!user.emailVerified) {
      setPollingIsActive(true);

      const unsubscribe = onAuthStateChanged(firebaseAuth, function (user) {
        if (user) {
          // User is signed in.
          setFbAuthUser(user);
        } else {
          // No user is signed in.
          navigate(redirectUrl, { replace: true });
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  // Poll for authentication status
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    const asyncEffect = async () => {
      if (pollingIsActive) {
        // poll every 2.5 seconds
        interval = setInterval(() => {
          setPollingCount((pollingCount) => pollingCount + 1);
        }, 2500);

        if (fbAuthUser) {
          // reload the user to get updated status
          await reload(fbAuthUser);
          const reloadedUser = firebaseAuth.currentUser;

          // If the user now has a verified email.
          // Navigate them to the page they were trying to get to
          if (reloadedUser && reloadedUser.emailVerified) {
            clearInterval(interval);
            setPollingIsActive(false);

            // Set verified flag to true in our db
            const ref = doc(firestore, 'users', reloadedUser.uid);
            setDoc(
              ref,
              { emailVerified: reloadedUser.emailVerified },
              { merge: true },
            );

            // Update user context
            updateUserContext({
              userContext: {
                ...userContext,
                user: { ...user, emailVerified: reloadedUser.emailVerified },
              },
            });

            // Update localstorage
            setAuth(reloadedUser);

            navigate(redirectUrl, { replace: true });
          }
        }
      } else if (!pollingIsActive && pollingCount > 0) {
        if (interval != null) {
          clearInterval(interval);
        }
      }

      if (pollingCount === 120) {
        // Stop polling after 5 minutes so as not to continually waste resources.
        // Likely that someone has left their tab open.
        // User must re-trigger polling.
        if (interval != null) {
          clearInterval(interval);
          setPollingIsActive(false);
        }
        // updateUser({ user: { ...user, emailVerified: true } });
      }
    };

    asyncEffect();

    // Return cleanup function from useEffect
    return () => {
      if (interval != null) {
        clearInterval(interval);
      }
    };
  }, [pollingIsActive, pollingCount]);

  if (!user.emailVerified) {
    return (
      <Layout
        colorTheme="light"
        title={`${t('title')} - CloudFit`}
        description={t('description')}
        hideHeader={false}
        noIndex>
        <Section.Container backgroundColor="light" id="verifyEmail.id">
          <Flex
            flexDirection="column"
            alignItems="center"
            mt={[5, 5, 6]}
            mx={[2, 2, 6]}>
            <TextLibrary.H2>{t('title')}</TextLibrary.H2>
            <TextLibrary.Paragraph textAlign="center">
              {t('description')} <strong>{user.email}</strong>
            </TextLibrary.Paragraph>
            <Flex flexDirection="column" alignItems="stretch" my={[3]}>
              {fbAuthUser && (
                <>
                  {pollingIsActive ? (
                    <Flex flexDirection="column" alignItems="stretch">
                      <Button disabled>
                        {t('checkingLabel')}{' '}
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      </Button>
                    </Flex>
                  ) : (
                    <Button
                      onClick={(event) => {
                        event.preventDefault;
                        setPollingCount(0), setPollingIsActive(true);
                      }}>
                      {t('checkAgainLabel')}
                    </Button>
                  )}

                  <Button
                    mt={[1, 1, 2]}
                    onClick={(event) => {
                      event.preventDefault;
                      navigate(`${rootPath}account/email`, { replace: true });
                    }}
                    variant="secondary">
                    Change email
                  </Button>

                  <Box as="span">
                    <VerifyEmailButton user={fbAuthUser} />
                  </Box>
                </>
              )}
            </Flex>
          </Flex>
        </Section.Container>
      </Layout>
    );
  } else {
    // redirect to root if a logged in user tried to get here directly via URL
    navigate(redirectUrl, { replace: true });
    return null;
  }
};

VerifyEmailGate.propTypes = {
  title: PropTypes.string,
  location: PropTypes.any,
};

export default VerifyEmailGate;
