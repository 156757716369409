import { User, sendEmailVerification } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rebass/styled-components';
import styled from 'styled-components';
import TextLibrary from '../TextLibrary';

const ErrorText = styled.span`
  display: none;
`;

// references for email verifiction button and error
const buttonRef = React.createRef<HTMLButtonElement>();
const errorRef = React.createRef<HTMLSpanElement>();

const VerifyEmailButton = ({ user }: { user: User }): React.JSX.Element => {
  const { t } = useTranslation('verifyEmail');

  const sendEmailVerificationLocal = async () => {
    if (buttonRef.current) {
      buttonRef.current.disabled = true;
      buttonRef.current.innerText = t('sendingLabel');
    }
    if (user) {
      try {
        await sendEmailVerification(user);
        if (buttonRef.current) {
          // buttonRef.current.style.display = 'none'; // Email sent.
          buttonRef.current.disabled = true;
          buttonRef.current.innerText = t('sentSuccessLabel');
        }
        if (errorRef.current) {
          errorRef.current.style.display = 'none';
        }
      } catch (error: any) {
        console.log(error);
        // An error happened.
        // start countdown timer
        resetTimer();
        toggleTimer();
        if (buttonRef.current) {
          buttonRef.current.innerText = t('buttonLabel');
        }
        if (errorRef.current) {
          errorRef.current.style.display = 'block';
        }
      }
    }
  };

  // EMAIL VERIFICATION CODE
  // Countdown timer to prevent people spamming the send email verification button on fail
  const [seconds, setSeconds] = useState(30);
  const [timerIsActive, setTimerIsActive] = useState(false);

  function toggleTimer() {
    setTimerIsActive(!timerIsActive);
  }

  function resetTimer() {
    setSeconds(30);
    setTimerIsActive(false);
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timerIsActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      // When countdown reaches zero - stop timer & re-enable send email button
      if (seconds <= 0) {
        setTimerIsActive(false);
        if (errorRef.current) {
          errorRef.current.style.display = 'none';
        }
        if (buttonRef.current) {
          buttonRef.current.disabled = false;
        }
      }
    } else if (!timerIsActive && seconds > 0) {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timerIsActive, seconds]);

  // END of countdown timer code

  return (
    <>
      <Button
        width={['180px', '200px', '220px']}
        ref={buttonRef}
        onClick={(event) => {
          event.preventDefault();
          sendEmailVerificationLocal();
        }}
        mt={[1, 1, 2]}
        mb={[0]}
        variant="secondary">
        {t('buttonLabel')}
      </Button>
      <TextLibrary.Caption mt={[2]} mb={[0]} color="cloudFitRed">
        <ErrorText ref={errorRef}>
          {t('sentErrorLabel')}
          {seconds ? `: ${seconds}` : ''}
        </ErrorText>
      </TextLibrary.Caption>
    </>
  );
};

export default VerifyEmailButton;
